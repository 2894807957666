<template>
  <div
    :key="profile.slug"
    class="d-flex flex-wrap justify-center fill-width py-2 px-4 mx-auto"
    style="max-width: 400px"
    >
    <div
      style="width: 90px; cursor: pointer;"
      class="text-center"
      @click="openFollowers"
      v-if="profile.profilableType == 'Store'"
      >
      <followers
        v-if="modal"
        ref="followers"
        :slug="profile.slug"
        @close="modal = false"
        ></followers>

      <div
        class="font-weight-bold secondary--text"
        :class="profile.profilableType == 'Store' ? 'body-2' : 'body-1'"
        >
        {{ profile.followersCount }} <span class="font-weight-regular">Fans</span>
      </div>
    </div>

    <v-row
      v-if="profile.profilableType != 'Store'"
      style="width: 90px; cursor: pointer;"
      class="justify-center d-flex flex-row"
      >
      <v-col
        cols="4"
        class="grow text-center"
        @click="openFollowing"
        >
        <following
          v-if="modal"
          ref="following"
          :slug="profile.slug"
          @close="modal = false"
          ></following>
        <div
          class="body-1 font-weight-bold secondary--text"
          >
          {{ profile.followingCount }}
        </div>
        <div
          class="caption"
          style="cursor: pointer;"
          >
          Partners que sigo
        </div>
      </v-col>

      <v-col
        cols="4"
        class="grow text-center"
        @click="openFanFollowing"
        >
        <fanFollowing
          v-if="modal"
          ref="fanFollowing"
          :slug="profile.slug"
          @close="modal = false"
          ></fanFollowing>

        <div
          class="body-1 font-weight-bold secondary--text"
          >
          {{ profile.fanFollowingCount }}
        </div>
        <div
          class="caption"
          style="cursor: pointer;"
          >
          Fans que sigo
        </div>
      </v-col>

      <v-col
        cols="4"
        class="grow text-center"
        @click="openFollowers"
        >
        <followers
          v-if="modal"
          ref="followers"
          :slug="profile.slug"
          @close="modal = false"
          ></followers>

        <div
          class="body-1 font-weight-bold secondary--text"
          >
          {{ profile.followersCount }}
        </div>
        <div
          class="caption"
          style="cursor: pointer;"
          >
          Fans que me siguen
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
const Followers = () => import('./Followers')
const Following = () => import('./Following')
const FanFollowing = () => import('./FanFollowing')

export default {
  data () {
    return {
      modal: false
    }
  },

  props: {
    profile: {
      type: Object,
      required: true
    }
  },

  methods: {
    openFollowers () {
      this.modal = true
      setTimeout( () => {
        this.$refs.followers.dialog = true
      }, 100)
    },

    openFollowing () {
      this.modal = true
      setTimeout( () => {
        this.$refs.following.dialog = true
      }, 100)
    },

    openFanFollowing () {
      this.modal = true
      setTimeout( () => {
        this.$refs.fanFollowing.dialog = true
      }, 100)
    },
  },

  components: { Followers, Following, FanFollowing }
}
</script>
