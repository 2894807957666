import gql from 'graphql-tag'
import { Message, Chat as ChatFragment } from '@/graphql/fragments'

export const Chat = gql`
  query chat($id: ID, $ids: [ID!]) {
    chat(id: $id, ids: $ids) {
      ...${ ChatFragment }
    }
  } 
`

export const Chats = gql`
  query chats {
    chats {
      id
      lastMessage
      participants {
        read
        profileId
      }
      profiles {
        id
        name
        profilableType
        picture {
          thumbUrl
        }
      }
    }
  } 
`

export const Messages = gql`
  query messages($chatId: ID!, $page: ID) {
    messages(chatId: $chatId, page: $page) {
      ...${ Message }
    }
  } 
`
