<template>
  <div>
    <div>
      <v-dialog
        v-model="modal"
        width="600"
        >
        <edit-picture
          v-if="modal"
          :profileId="profile.id"
          @close="reload"
          ></edit-picture>
      </v-dialog>

      <v-img
        v-if="attributes.picture"
        height="150"
        width="150"
        :src="url + attributes.picture.url"
        class="rounded-circle mx-auto"
        ></v-img>

      <v-card
        v-else
        class="rounded-circle mx-auto"
        flat
        height="150"
        width="150"
        color="grey"
        >
        <v-card-text
          class="fill-height d-flex align-center justify-center"
          >
          <v-icon
            color="white"
            size="75"
            >
            mdi-account
          </v-icon>
        </v-card-text>
      </v-card>
    </div>

    <div
      class="text-center mt-n4"
      v-if="currentUser && (attributes.id == currentUser.selectedProfile.id)"
      >
      <v-btn
        @click="openModal"
        fab
        x-small
        depressed
        color="primary"
        style="border: 2px solid white !important"
        >
        <v-icon
          size="16"
          >
          mdi-camera
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const EditPicture = () => import('./EditPicture')

export default {
  data: () => ({
    attributes: null,
    modal: false
  }),

  computed: {
    ...mapGetters(['currentUser', 'url'])
  },

  props: {
    profile: {
      type: Object,
      required: false
    }
  },

  created () {
    if (!this.profile) {
      this.attributes = this.currentUser.selectedProfile
    } else {
      this.attributes = this.profile
    }
  },

  methods: {
    openModal () {
      if (this.currentUser.id == this.profile.userId) {
        this.modal = true
      }
    },

    reload () {
      this.modal = false
      this.$emit('reload')
    },
  },

  components: {
    EditPicture
  }
}
</script>
