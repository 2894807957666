<template>
  <div
    class="text-center"
    >
    <template
      v-if="currentUser"
      >
      <div
        v-if="profile.id != currentUser.selectedProfile.id"
        style="gap: 8px"
        class="d-flex mx-auto align-center"
        >
        <v-btn
          outlined
          :color="following ? '' : 'primary'"
          x-small
          depressed
          @click="follow"
          class="py-3 rounded-lg grow"
          >
          {{ following ? 'Dejar de ser Fan' : '¡Ser Fan!' }}
        </v-btn>

        <v-btn
          x-small
          outlined
          @click="message"
          class="py-3 rounded-lg grow"
          >
          Mensaje
        </v-btn>

        <report
          :profile="profile"
          @reload="reportProfile"
          ></report>

        <v-snackbar
          :timeout="4000"
          v-model="success"
          :color="!following ? 'error' : 'success'"
          >
          <div
            class="text-center"
            >
            <template
              v-if="!following"
              >
              ¡Ya no eres Fan de {{ profile.name }}!
            </template>
            <template
              v-else
              >
              ¡Ahora eres Fan de {{ profile.name }}!
            </template>
          </div>
        </v-snackbar>
      </div>

      <div
        class="d-flex flex-column mx-auto"
        style="max-width: 300px"
        v-else
        >
        <edit
          v-if="profile.profilableType == 'User'"
          :profile="profile"
          ></edit>

        <v-btn
          v-if="profile.profilableType == 'Store' && profile.id == currentUser.selectedProfile.id"
          x-small
          outlined
          depressed
          class="py-3 rounded-lg"
          color="secondary"
          :to="{ name: 'admin' }"
          >
          <v-icon
            small
            class="mr-1"
            style="margin-top: -2px"
            >
            mdi-storefront
          </v-icon>
          <div>
            Administrar mi tienda
          </div>
        </v-btn>

        <div
          class="mt-2"
          >
          <share></share>
        </div>

        <disable-account
          ></disable-account>
      </div>

      <div
        class="d-flex flex-column mx-auto mt-2"
        v-if="profile.profilableType == 'Store' && profile.id != currentUser.selectedProfile.id"
        >
        <create-post
          postType="recommend_it"
          grow
          :profile="profile"
          xSmall
          ></create-post>

        <div
          class="mt-2"
          >
          <share></share>
        </div>
      </div>

    </template>

    <template
      v-else
      >
      <div
        class="py-4"
        >
        <v-btn
          small
          block
          color="primary"
          :to="{ name: 'signup' }"
          class="elevation-6"
          >
          ¡Regístrate gratis ahora!
        </v-btn>
      </div>
    </template>

    <v-alert
      v-if="reported"
      dense
      outlined
      class="rounded-lg mt-2"
      type="error"
      icon="mdi-information"
      border="left"
      >
      Reportaste este perfil.
    </v-alert>
  </div>
</template>

<script>
import { Follow } from '@/graphql/mutations/profiles'
import { Chat } from '@/graphql/queries/chats'
import { mapGetters } from 'vuex'

const Share = () => import('@/components/shared/Share')
const CreatePost = () => import('@/components/posts/Create')
const Edit = () => import('@/components/profiles/Edit')
const Report = () => import('@/components/profiles/Report')
const DisableAccount = () => import('@/components/profiles/DisableAccount')

export default {
  data () {
    return {
      success: false,
      reported: false,
    }
  },

  props: {
    profile: {
      type: Object,
      required: true
    },
    row: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  watch: {
    currentUser () {
      this.reported = this.currentUser.reportedProfilesIds.includes(this.profile.id)
    }
  },

  computed: {
    ...mapGetters(['currentUser']),

    following () {
      return this.profile.followerIds.includes(this.currentUser.selectedProfile.id)
    }
  },

  methods: {
    follow () {
      this.$apollo.mutate({
        mutation: Follow,
        variables: {
          input: {
            id: this.profile.id
          }
        }
      }).then ( res => {
        this.$emit('reload')
        setTimeout( () => {
          this.success = true
        }, 200)
      })
    },

    message () {
      this.$apollo.query({
        query: Chat,
        variables: {
          ids: [this.profile.id, this.currentUser.selectedProfile.id]
        }
      }).then ( res => {
        this.$router.push({ name: 'chat', params: { id: res.data.chat.id } })
      })
    },

    reportProfile () {
      this.reported = true
    },
  },

  components: { CreatePost, Edit, Report, DisableAccount, Share }
}
</script>
