<template>
  <div
    :style="$vuetify.breakpoint.mobile ? '' : 'position: sticky; top: 60px; left: 0'"
    >
    <change-profile
      v-if="(currentUser && (profile.id == currentUser.selectedProfile.id))"
      ref="changeProfile"
      ></change-profile>

    <v-card
      flat
      color="white"
      class="rounded-lg"
      v-if="profile"
      >
      <v-card-text>
        <div
          class="mb-3"
          >
          <div>
            <v-chip
              @click="$refs.changeProfile.dialog = true"
              class="fill-width font-weight-medium grey lighten-4 text-uppercase mb-3 text--primary justify-center rounded-lg"
              >
              {{ profile.name }}
              <v-icon
                style="position: absolute; right: 8px"
                color="black"
                v-if="currentUser && (profile.id == currentUser.selectedProfile.id)"
                >
                mdi-chevron-down
              </v-icon>
            </v-chip>
          </div>

          <profile-picture
            :profile="profile"
            @reload="$emit('reload')"
            ></profile-picture>
        </div>

        <div
          v-if="profile && profile.description"
          class="pb-3 text-center"
          >
          {{ profile.description }}
        </div>

        <div
          class="mb-2"
          >
          <actions
            :profile="profile"
            @reload="$emit('reload')"
            ></actions>
        </div>
        <statistics
          :profile="profile"
          ></statistics>
      </v-card-text>

      <v-tabs
        :vertical="$vuetify.breakpoint.mdAndUp"
        :icons-and-text="!$vuetify.breakpoint.mdAndUp"
        fixed-tabs
        v-model="tab"
        >
        <v-tab
          v-if="profile.profilableType == 'Store'"
          @change="selected = 'store'"
          :style="$vuetify.breakpoint.mdAndUp ? 'gap: 12px' : ''"
          :class="$vuetify.breakpoint.mdAndUp ? 'align-center justify-start' : ''"
          >
          <template
            v-if="$vuetify.breakpoint.smAndDown"
            >
            Tienda
          </template>
          <v-icon>
            {{ tab == 'store' ? 'mdi-storefront' : 'mdi-storefront-outline' }}
          </v-icon>
          <template
            v-if="$vuetify.breakpoint.mdAndUp"
            >
            Tienda
          </template>
        </v-tab>

        <v-tab
          @change="selected = 'posts'"
          :style="$vuetify.breakpoint.mdAndUp ? 'gap: 12px' : ''"
          :class="$vuetify.breakpoint.mdAndUp ? 'align-center justify-start' : ''"
          >
          <template
            v-if="$vuetify.breakpoint.smAndDown"
            >
            Publicaciones
          </template>

          <v-icon>
            {{ tab == 'posts' ? 'mdi-post' : 'mdi-post-outline' }}
          </v-icon>

          <template
            v-if="$vuetify.breakpoint.mdAndUp"
            >
            Publicaciones
          </template>
        </v-tab>

        <v-tab
          @change="selected = 'orders'"
          v-if="currentUser && (profile.id == currentUser.selectedProfile.id && profile.profilableType == 'User')"
          style="gap: 12px"
          :style="$vuetify.breakpoint.mdAndUp ? 'gap: 12px' : ''"
          :class="$vuetify.breakpoint.mdAndUp ? 'align-center justify-start' : ''"
          >
          <template
            v-if="$vuetify.breakpoint.smAndDown"
            >
            Mis órdenes
          </template>

          <v-icon>
            {{ tab == 'orders' ? 'mdi-package-variant' : 'mdi-package-variant-closed' }}
          </v-icon>

          <template
            v-if="$vuetify.breakpoint.mdAndUp"
            >
            Mis órdenes
          </template>
        </v-tab>
      </v-tabs>
    </v-card>

    <div
      v-if="store && selected == 'store'"
      class="mt-3"
      >
      <v-card
        flat
        class="rounded-lg mb-2"
        >
        <v-card-text
          class="text--primary description-box py-2 d-flex align-center"
          style="gap: 8px"
          >
          <div>
            <v-icon color="primary">mdi-storefront</v-icon> 
          </div>

          <div>
            {{ store.information.businessField }}
          </div>
        </v-card-text>
      </v-card>

      <v-card
        flat
        class="rounded-lg mb-2"
        >
        <v-card-text
          class="text--primary description-box py-2 d-flex align-center"
          style="gap: 8px"
          >
          <div>
            <v-icon color="primary">mdi-file-document</v-icon> 
          </div>

          <div>
            {{ store.description }}
          </div>
        </v-card-text>
      </v-card>

      <v-card
        flat
        class="rounded-lg"
        v-if="store.information.fullAddress"
        >
        <v-card-text
          class="text--primary description-box py-2 d-flex align-center"
          style="gap: 8px"
          >
          <div>
            <v-icon color="primary">mdi-map-marker</v-icon> 
          </div>

          <div>
            {{ store.information.fullAddress }}
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ProfilePicture from './ProfilePicture'
import Statistics from './Statistics'
import Actions from './Actions'

const ChangeProfile = () => import('./ChangeProfile')

export default {
  data () {
    return {
      tab: 0,
      selected: null,
      modal: false,
      modal2: false,
      dialog: false,
    }
  },

  watch: {
    tab (value) {
      this.$emit('updateTab', this.selected)
    }
  },

  props: {
    profile: {
      required: true,
      type: Object
    },

    store: {
      required: false,
      type: Object
    }
  },

  created () {
    if (this.profile.profilableType == 'Store') {
      this.selected = 'store'
    } else {
      this.selected = 'posts'
    }
  },

  computed: {
    ...mapGetters(['currentUser', 'url'])
  },

  methods: {
    signout () {
      localStorage.removeItem('auth_token')
      localStorage.removeItem('order_token')
      this.$router.go()
    },
  },

  components: { Statistics, Actions, ChangeProfile, ProfilePicture } 
}
</script>
