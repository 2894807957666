import gql from 'graphql-tag'
import { Profile as ProfileFragment } from '@/graphql/fragments'

export const Profile = gql`
  query profile($slug: ID!) {
    profile(slug: $slug) {
      ...${ ProfileFragment }
    }
  } 
`

export const Profiles = gql`
  query profiles($ids: [ID!]) {
    profiles(ids: $ids) {
      ...${ ProfileFragment }
    }
  } 
`

export const BaseProfiles = gql`
  query profiles($ids: [ID!]) {
    profiles(ids: $ids) {
      id
      name
      slug
      picture {
        id
        thumbUrl
      }
    }
  } 
`

export const RecommendedProfiles = gql`
  query recommendedProfiles {
    recommendedProfiles {
      ...${ ProfileFragment }
    }
  } 
`

export const Followers = gql`
  query followers($slug: ID!) {
    followers(slug: $slug) {
      ...${ ProfileFragment }
    }
  } 
`

export const Following = gql`
  query following($slug: ID, $type: String) {
    following(slug: $slug, type: $type) {
      ...${ ProfileFragment }
    }
  } 
`

export const ProfileChats = gql`
  query profileChats {
    profileChats {
      ...${ ProfileFragment }
    }
  } 
`
