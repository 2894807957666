import gql from 'graphql-tag'

export const CreateStore = gql`
  mutation createStore($input: CreateStoreInput!) {
    createStore(input: $input) {
      success
    }
  }
`

export const CreateTimeTracker = gql`
  mutation createTimeTracker($input: CreateTimeTrackerInput!) {
    createTimeTracker(input: $input) {
      success
    }
  }
`

export const EditAboutUs = gql`
  mutation editAboutUs($input: EditAboutUsInput!) {
    editAboutUs(input: $input) {
      success
    }
  }
`
