<template>
  <v-container
    v-if="profile"
    :key="profile.slug"
    style="max-width: 982px"
    class="mx-auto"
    >
    <div
      v-if="profile.disabled"
      >
      <v-alert
        border="left"
        type="info"
        prominent
        outlined
        >
        <v-row align="center">
          <v-col class="grow">
            El perfil que intenta visualizar se encuentra temporalmente deshabilitado.
          </v-col>
          <v-col 
            class="shrink">
            <v-btn
              color="info"
              depressed
              :to="{ name: 'home' }"
              >Ir al inicio</v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </div>

    <div
      v-else
      >
      <template
        v-if="$vuetify.breakpoint.smAndDown"
        >
        <v-row
          v-if="header && header.mobileBanners.length"
          class="mb-3"
          >
          <v-col
            cols="12"
            class="mx-auto px-3 px-md-0"
            >
            <v-carousel
              class="rounded-lg fill-width"
              hide-delimiters
              cycle
              :show-arrows="!$vuetify.breakpoint.mobile && (header.mobileBanners.length > 1)"
              style="max-height: 400px"
              height="auto"
              >
              <v-carousel-item
                v-for="banner in header.mobileBanners"
                :src="url + banner.imageUrl"
                ></v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>
      </template>
      <template
        v-else
        >
        <v-row
          v-if="header && header.banners.length"
          class="mb-3"
          >
          <v-col
            cols="12"
            class="mx-auto px-3 px-md-0"
            style="max-width: 958px"
            >
            <v-carousel
              class="rounded-lg fill-width"
              hide-delimiters
              cycle
              :show-arrows="!$vuetify.breakpoint.mobile && (header.banners.length > 1)"
              style="max-height: 300px"
              height="auto"
              >
              <v-carousel-item
                v-for="banner in header.banners"
                :src="url + banner.imageUrl"
                style="max-height: 300px"
                ></v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>
      </template>

      <div
        class="pb-3"
        >
        <v-row
          no-gutters
          >
          <v-col
            cols="12"
            lg="4"
            class="pr-md-3"
            style="position: relative; z-index: 3"
            >
            <profile-header
              :profile="profile"
              :store="store"
              @updateTab="updateTab"
              @reload="fetchProfile(true)"
              ></profile-header>
          </v-col>

          <v-col
            cols="12"
            lg="8"
            class="px-md-0 mt-md-0 mt-3"
            >
            <template
              v-if="tab == 'posts'"
              >
              <posts
                :profileId="this.profile.id"
                no-margin
                ></posts>
            </template>

            <template
              v-if="tab == 'store'"
              >
              <products
                :profile="profile"
                />
            </template>

            <template
              v-if="tab == 'orders'"
              >
              <orders
                outlined
                />
            </template>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { Profile } from '@/graphql/queries/profiles'
import { CreateTimeTracker } from '@/graphql/mutations/stores'
import { PublicStore } from '@/graphql/queries/stores'
import { Header } from '@/graphql/queries/stores'

import axios from 'axios'

import ProfileHeader from '@/components/profiles/Header.vue'

const Posts = () => import('@/components/posts/Posts')
const Products = () => import('@/components/profiles/Products')
const Orders = () => import('@/components/orders/List')

export default {
  name: "Profile",

  data () {
    return {
      profile: null,
      tab: null,
      mountTime: null,
      store: null,
      header: null,
    }
  },

  watch: {
    '$route.params.slug': {
      handler (val) {
        this.fetchProfile()
      }
    }
  },

  components: {
    ProfileHeader, Products, Posts, Orders
  },

  computed: {
    ...mapGetters(['url'])
  },

  created () {
    this.fetchProfile ()
    this.mountTime = Date.now()
  },

  beforeDestroy()  {
    if (this.profile.profilableType == 'Store') {
      const name = this.$options.name // component name.
      const elapsed = Date.now() - this.mountTime
      // send data to API somehow, i.e. through a Store action or simply use axios or sth.
      this.$apollo.mutate({
        mutation: CreateTimeTracker,
        variables: {
          input: {
            time: elapsed,
            storeId: this.profile.profilableId
          }
        }
      }).then ( res => {
        // console.log(res)
      })
    }
  },

  methods: {
    fetchProfile (reload = false, policy = 'no-cache') {
      this.$apollo.query({
        query: Profile,
        variables: {
          slug: this.$route.params.slug
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.profile = res.data.profile

        if (this.profile.profilableType == 'Store') {
          this.fetchStore ()
        } else {
          this.store = null
          this.header = null
          this.tab = 'posts'
        }
      })
    },

    fetchStore () {
      this.$apollo.query({
        query: PublicStore,
        variables: {
          slug: this.profile.profilableId
        }
      }).then ( res => {
        this.store = res.data.publicStore

        if (this.profile.profilableType == 'Store') {
          this.tab = 'store' 
        } else {
          this.tab = 'posts'
        }

        this.fetchStoreHeader ()
      })
    },

    fetchStoreHeader () {
      this.$apollo.query({
        query: Header,
        variables: {
          slug: this.store.slug
        }
      }).then ( res => {
        this.header = res.data.storeHeader
      })
    },

    updateTab (tab) {
      this.tab = tab
    },
  }
}
</script>
